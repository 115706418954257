<template>
  <div class="pt-6 px-4">
    <div class="w-full grid gap-4 pb-6 account-summery">
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
        <h2>Account summary</h2>
        <div class="w-full grid grid-cols-2 gap-4 pb-6">
          <div class="col-1">
            <div class="bg-white rounded-lg 2xl:col-span-2 meeting-sp">
              <div class="py-6">
                <div class="flex max-w-md bg-white rounded-lg overflow-hidden">
                  <div
                    class="w-1/3 bg-cover rounded-lg"
                    style="
                      background-image: url('https://images.unsplash.com/photo-1494726161322-5360d4d0eeae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80');
                    "></div>
                  <div class="w-2/3 p-4">
                    <h3 class="bg-blue-500 rounded-full text-white px-5 py- font-normal">Beginner Consultant</h3>
                    <p class="mt-2 text-gray-600 text-sm">Overall contribution and performance</p>
                    <rating-bar :rating="rating" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="bg-white rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 meeting-sp">
              <div class="w-full grid grid-cols-4 gap-4 pb-6">
                <div class="col-1 text-center">
                  <p class="count text-3xl font-bold">{{ clients }}</p>
                  <p class="text-sm">Clients</p>
                </div>
                <div class="col-1 text-center">
                  <p class="count text-3xl font-bold">{{ upcomingSessions.length }}</p>
                  <p class="text-sm">Upcoming sessions</p>
                </div>
                <div class="col-1 text-center">
                  <p class="count text-3xl font-bold">{{ completedMeetings }}</p>
                  <p class="text-sm">Completed Meetings</p>
                </div>
                <div class="col-1 relative">
                  <round-progress class="absolute bottom-0" strokeColor="#3882F6" value="45" :strokeWidth="5" :radius="20" style="height: 20px">
                    <template v-slot:footer>
                      <p class="text-sm">Completion</p>
                    </template>
                    <template v-slot:default>
                      <p class="text-sm">45%</p>
                    </template>
                  </round-progress>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full grid grid-cols-12 gap-4 pb-6">
      <div class="col-span-5 session-into user-avatar-details">
        <div class="rounded-lg meeting-sp relative">
          <img class="w-full p-6 sm:p-6 xl:p-8 bg-white shadow rounded" src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=150" />
          <div class="w-full p-6 sm:p-6 xl:p-8 overfow-heading bg-white shadow rounded-lg card-details">
            <div class="grid grid-cols-12">
              <div class="col-span-10">
                <h3>Meeting with Jake Alberta</h3>
              </div>
              <div class="col-span-2">
                <p class="date">08<span>APRIL</span></p>
              </div>
            </div>
            <div class="grid grid-cols-12 sec-row">
              <div class="col-span-8">
                <p class="job-title">CERTIFICATE UX/UI design</p>
                <p class="time">Begin in 15 mins</p>
              </div>
              <div class="col-span-4 join-now">
                <p>Join Now</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-7">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 meeting-sp upcoming-sessions">
          <h2 class="pb-5">Upcoming Sessions</h2>
          <div class="grid grid-cols-12 pb-8">
            <div class="col-span-2">
              <p>Date</p>
            </div>
            <div class="col-span-6">
              <p class="date">Name of the client</p>
            </div>
            <div class="col-span-4">
              <p class="date">Time</p>
            </div>
          </div>

          <div v-for="(schedule, idx) in calendar" :key="idx" class="grid grid-cols-12 pb-5">
            <div class="col-span-2 text-center flex items-center start-date">
              <span>{{ schedule.date }}</span>
            </div>
            <div v-for="(session, idx) in schedule.sessions" :key="idx" class="col-span-10 col-start-3 rounded-lg p-5 start-time">
              <div class="grid grid-cols-12">
                <div class="col-span-7 client-name">
                  <p class="session-person-name">{{ session.client }}</p>
                  <span class="">{{ session.tagline }}</span>
                </div>
                <div class="col-span-5 time">
                  <span class="">{{ session.time }}</span>
                </div>
              </div>
            </div>
          </div>
          <a href="" class="see-all">See All Sessions</a>
        </div>
      </div>
    </div>

    <div class="w-full grid gap-4 pb-6 testimonials mt-12">
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2">
        <h2 class="pb-8">Recomendations</h2>
        <div class="item">
          <div class="inline-block mb-6 rounded-full pr-5 h-16 line-height-username1 avatar">
            <img class="rounded-full float-left h-full" src="https://randomuser.me/api/portraits/women/34.jpg" /> <span class="ml-3">Maria Lopez, VP of Design at Meshery</span>
          </div>
          <p class="pb-5">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.”</p>
        </div>
        <div class="item">
          <div class="inline-block mb-6 rounded-full pr-5 h-16 line-height-username1 avatar">
            <img class="rounded-full float-left h-full" src="https://randomuser.me/api/portraits/women/34.jpg" /> <span class="ml-3">Maria Lopez, VP of Design at Meshery</span>
          </div>
          <p class="pb-5">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.”</p>
        </div>
        <div class="load-more w-full text-center p-7 see-all">See all recomendations</div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingBar from '@/components/RatingBar.vue';
import Progress from 'easy-circular-progress';
import consultant_service from '@/services/consultant_service';

export default {
  name: 'consultant_summary',
  components: {
    'rating-bar': RatingBar,
    'round-progress': Progress,
  },
  data() {
    return {
      clients: 5,
      rating: 5,
      upcomingSessions: [
        { date: 'March 12, 2022', client: 'Bruce Wayne', tagline: 'Batman', time: '8:30am - 1:00pm' },
        { date: 'March 12, 2022', client: 'Bruce Banner', tagline: 'Hulk', time: '1:30pm - 5:00pm' },
        { date: 'March 13, 2022', client: 'Steve Rogers', tagline: 'Captain America', time: '8:30am - 1:00pm' },
        { date: 'March 13, 2022', client: 'Buckey Barns', tagline: 'Winter Soldier', time: '1:30pm - 5:00pm' },
        { date: 'March 14, 2022', client: 'Peter Parker', tagline: 'Spider Man', time: '8:30am - 1:00pm' },
        { date: 'March 14, 2022', client: 'Tony Stark', tagline: 'Iron Man', time: '1:30pm - 5:00pm' },
      ],
      completedMeetings: 12,
      styles: {},
    };
  },
  computed: {
    calendar() {
      let dates = [];
      this.upcomingSessions.slice(0, 6).forEach((session) => {
        let schedule = dates.find((item) => item.date === session.date);
        if (schedule === undefined) {
          dates.push({ date: session.date, sessions: [session] });
        } else {
          schedule.sessions.push(session);
        }
      });
      return dates;
    },
    consultant() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.$s3.getDpUrl(this.consultant.id).then((url) => {
      this.styles = { 'background-image': `image(${url})` };
    });
    consultant_service
      .getBookings(this.consultant.id)
      .then(({ data }) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        this.upcomingSessions = data.bookings.map((session) => {
          let date = new Date(session.scheduledAt);
          return {
            date: date.toLocaleDateString('en-US', options),
            client: session.clientName,
            tagline: session.tagLine,
            time: '8:30am - 1:00pm',
          };
        });
      })
      .catch(({ response }) => {
        this.$msg.error({ title: 'Unable to fetch booking details', message: response.data.error });
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.session-person-name {
  color: #021442;
  font-size: 12px;
  font-weight: 600;
}
.session-person-banner {
  color: #9592a6;
}
</style>
